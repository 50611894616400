import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.css']
})
export class CampaignComponent implements OnInit {
  view;
  constructor(private route:ActivatedRoute) {
    this.route.params.subscribe(params=>{
      console.log(params)
      if(params)
      {
        if(params.id == 'all')
        {
          this.view = 'campaigns';
        }
        else if (params.id && params.view=='report')
        {
          this.view = 'report';
        }
        else if(params.id == 'create')
        {
          this.view = 'create';
        }
        else
        {
          this.view = 'campaign-profile'
        }
      }
		});
  }

  ngOnInit() {
  }

}
