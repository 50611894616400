import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
declare var FB: any;

@Component({
  selector: 'app-hashtag-posts',
  templateUrl: './hashtag-posts.component.html',
  styleUrls: ['./hashtag-posts.component.css']
})
export class HashtagPostsComponent implements OnInit {
  sponsoredRegex = /(partner|(#ad\b)|(#ad21\+)|#sponsored|(\bad\b)|(\bsponsored\b))/i
  giveawayRegex = /(giveaway|(#giveaway\b)|entries|(\bgiveaway\b)|(\bG I V E A W A Y\b)|(#sweepstakes\b)|(#contest\b))/gi;
  sortPostsBy = "default";
  hashtagProfileSub;
  hashtagPosts;
  filteredPosts;
  responses:number = 0;
  coordinatorIGAccountIdSub;
  coordinatorIGAccountId;

  pageLength;
  splicedData;
  pageSize: number = 25;
  pageIndex = 0;
  showMorePostsInView:boolean;
  pagingCursor;

  constructor(private dataService: DataService) { 
    this.coordinatorIGAccountIdSub = this.dataService.coordinatorIGAccountObject.subscribe((resp) =>{
      if(typeof resp != 'object')
      {
        this.coordinatorIGAccountId = resp;
        sessionStorage.setItem('coordinatorIGAccountId', resp)
      }
    })

    this.hashtagProfileSub = this.dataService.hashtagSearchObject.subscribe((resp) =>{
      this.hashtagPosts = resp;
      this.responses++;

      if(this.responses >= 1) 
      {
        this.filteredPosts = this.postsInit(this.hashtagPosts.data);
        this.checkPagingCursors();
      }
    });
  }

  ngOnInit() {
  }

  postsInit(posts)
  {
    posts.forEach((post) => {
      post.engagements = post.comments_count + post.like_count;
      post.sponsored = this.sponsoredRegex.test(post.caption) ? true : false;
      post.giveaway = this.giveawayRegex.test(post.caption) ? true : false;

      if(post.media_type == "CAROUSEL_ALBUM")
      {
        if(post.children && post.children.data && post.children.data.length)
        {
          let result = post.children.data.filter(c => c.media_url);
          post.children_with_url = result;
        }
      }
    });

    return posts;
  }

  checkPagingCursors()
  {
    if(this.hashtagPosts.paging && this.hashtagPosts.paging.cursors.after)
    {
      this.showMorePostsInView = true;
      this.pagingCursor = this.hashtagPosts.paging.cursors.after;

    }
    else
    {
      this.showMorePostsInView = false;
    }
  }

  onSortPosts(string)
  {
    this.sortPostsBy = string;
    this.filteredPosts = this.hashtagPosts.data;

    if(string == 'default')
    {
      this.filteredPosts = this.hashtagPosts.data;
    }
    else if(string == 'top')
    {
      this.filteredPosts = this.hashtagPosts.data.sort((a,b) => {
        return b.engagements - a.engagements;
      })
    }
    else if(string == 'sponsored')
    {
      this.filteredPosts = this.hashtagPosts.data.filter(post => post.sponsored);
    }
    else if(string == "giveaways")
    {
      this.filteredPosts = this.hashtagPosts.data.filter(post => post.giveaway);
    }
    else
    {
      this.filteredPosts = this.hashtagPosts.data.filter(post => !post.sponsored);
    }
  }

  morePosts
  //one thing that works is not pulling any fields but thats not very helpful
  onGetMorePostsClick()
  {
    let query = "/"+this.hashtagPosts.hashtag_id+"/"+this.hashtagPosts.sort;
    let userId = this.coordinatorIGAccountId.substring(1);

    FB.api(
      query,
      'GET',
      {"fields":"id,like_count,comments_count,media_type,media_url,permalink,children{media_url,media_type}","user_id":userId,"after":this.pagingCursor},
      (response) => {
        let posts = this.postsInit(response.data);
        this.filteredPosts = this.filteredPosts.concat(posts);
      }
    );
  }

}
