import { Component, Input, OnInit } from '@angular/core';
const layout = "layout-a"
@Component({
  selector: 'app-page-influencer-layout-a',
  templateUrl: './page-influencer-layout-a.component.html',
  styleUrls: ['./page-influencer-layout-a.component.css']
})
export class PageInfluencerLayoutAComponent implements OnInit {
  @Input() influencer;
  activeMediaIndex:number;
  activeMediaUrl:string;
    constructor() { }

  ngOnInit() {
        this.influencer.activeMediaIndex = this.influencer.activeMediaIndex ? this.influencer.activeMediaIndex : -1;
      this.influencer.activeMediaIndex > -1 ? this.changeActiveMediaUrl(0) : this.changeActiveMediaUrl(+1)
      
    }

    changeActiveMediaUrl(direction)
    {
      if (direction) this.influencer.activeMediaIndex = this.influencer.activeMediaIndex + direction;
      this.activeMediaUrl = this.influencer.media.data[this.influencer.activeMediaIndex].media_url;
    }

}
