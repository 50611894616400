import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { DataService } from '../data.service';
import { FacebookApiService } from '../facebook-api.service';
import { Router } from '@angular/router';
declare var FB: any;

@Component({
  selector: 'app-hashtag-search',
  templateUrl: './hashtag-search.component.html',
  styleUrls: ['./hashtag-search.component.css']
})
export class HashtagSearchComponent implements OnInit {
  @Input() view;
  @ViewChild('hashtagSearch') hashtagSearch:ElementRef;
  coordinatorIGAccountIdSub: any;
  coordinatorIGAccountId: string;
  hasError:boolean = false;
  hashtagSearchOptions = {"hashtag": '', 'media': 'top_media'};
  searchedHashtag;

  constructor(private dataService: DataService, public router:Router) {
    this.coordinatorIGAccountIdSub = this.dataService.coordinatorIGAccountObject.subscribe((resp) =>{
      if(typeof resp != 'object')
      {
        this.coordinatorIGAccountId = resp;
        sessionStorage.setItem('coordinatorIGAccountId', resp)
      }
    })
  }

  ngOnInit() {
  }

  searchHashtagId()
  {
    this.hasError = false;
    let id = this.coordinatorIGAccountId.substring(1);
    let hashtag = this.hashtagSearchOptions.hashtag.charAt(0) == "#" ? this.hashtagSearchOptions.hashtag.substring(1).toLowerCase() : this.hashtagSearchOptions.hashtag.toLowerCase();

    FB.api(
      '/ig_hashtag_search',
      'GET',
      {"user_id":`${id}`,"q": `${hashtag}`},
      (response) => {
        if(response && response.data.length)
        {
          this.searchHashtagData(response.data[0].id) //response.data[0].id gives us hashtag id
        }
        else
        {
          this.hasError = true;
        }
      }
    );
  }

  searchHashtagData(hashtag_id)
  {
    let coordinatorId = this.coordinatorIGAccountId.substring(1);
    FB.api(
      `/${hashtag_id}/${this.hashtagSearchOptions.media}`,
      'GET',
      {"fields":"id,like_count,comments_count,media_type,media_url,permalink,caption,children{media_url,media_type}","limit":"50","user_id":`${coordinatorId}`},
      (response) => {
        this.searchedHashtag = response;
        this.searchedHashtag.name = this.hashtagSearchOptions.hashtag.charAt(0) == "#" ? this.hashtagSearchOptions.hashtag.substring(1).toLowerCase() : this.hashtagSearchOptions.hashtag.toLowerCase();
        this.searchedHashtag.sort = this.hashtagSearchOptions.media;
        this.searchedHashtag.hashtag_id = hashtag_id;
      }
    );
  }

  //route to influencer profile and clear search box on influencer profile page if used
  onSearchedHashtagClicked()
  {
    this.dataService.updateSearchedHashtag(this.searchedHashtag);
    this.router.navigate(['/hashtag', this.searchedHashtag.name]);
    this.searchedHashtag = {};
    if(this.hashtagSearch)
    {
      this.hashtagSearchOptions.hashtag = "";
      this.hashtagSearch.nativeElement.value = '';
    }
  }

}
