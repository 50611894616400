import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-campaign-detail',
  templateUrl: './campaign-detail.component.html',
  styleUrls: ['./campaign-detail.component.css']
})
export class CampaignDetailComponent implements OnInit {
  campaignSub;
  campaign;
  influencers;
  campaignTotals:any = {};
  sortCandidateListBy:string = 'all';
  processing:any;
  public onFileSaved: Function;
  constructor(private dataService: DataService) {
    this.campaignSub = this.dataService.campaignObject.subscribe((campaign) => {
      if(campaign && campaign.id)
      {
        this.campaign = campaign;
      }
    })
  }

  ngOnInit() {
    this.onFileSaved = this.myCallback.bind(this);
    this.campaign = this.campaign ? this.campaign : JSON.parse(sessionStorage.getItem('activeCampaign')) ? JSON.parse(sessionStorage.getItem('activeCampaign'))  : "";
    if(this.campaign)
    {
      this.getInfluencers(this.campaign.id);
    }
  }

  myCallback(val)
    {
        // Do something once Export returns
        this.processing = false;
    }

  removeFromList(idx)
  {
    this.influencers = this.influencers.slice(0, idx).concat(this.influencers.slice(idx + 1, this.influencers.length))
  }

  getInfluencers(campaign_id)
  {
    this.influencers = this.dataService.returnCampaignProfilesList(this.campaign.id) ? this.dataService.returnCampaignProfilesList(this.campaign.id).profiles : [];
    if (this.influencers && this.influencers.length) this.defineCampaignMetrics();
  }

  consoleInfluencers()
  {
    console.log(this.influencers)
  }
 
  defineCampaignMetrics()
  {
    let totalFollowerCount = 0;
    let totalLikes = 0;
    let totalComments = 0;
    let totalER = 0;

    this.influencers.forEach((i) => {
      totalFollowerCount = totalFollowerCount + i.followers_count; // totalFollowerCount + i.followersCount;
      totalLikes = totalLikes + i.average_likes; // totalLikes + i.averageLikesPerPost;
      totalComments = totalComments + i.average_comments; // totalComments + i.averageCommentsPerPost;
      totalER = totalER + i.account_avg_eng_rate; // totalER + i.averageEngagementRatePerPost;
    })

    this.campaignTotals.campaignFollowerCount = totalFollowerCount;
    this.campaignTotals.campaignAvgLikes = totalLikes / this.influencers.length;
    this.campaignTotals.campaignAvgComments = totalComments / this.influencers.length;
    this.campaignTotals.campaignAvgEngRate = totalER / this.influencers.length;
  }

}
