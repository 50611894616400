import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private coordinatorIGAccountSource = new BehaviorSubject<any>({});
  coordinatorIGAccountObject = this.coordinatorIGAccountSource.asObservable();

  private influencerProfileDataSource = new BehaviorSubject<any>({});
  influencerProfileDataObject = this.influencerProfileDataSource.asObservable();

  private coordinatorLoggedInSource = new BehaviorSubject<any>({});
  coordinatorLoggedInObject = this.coordinatorLoggedInSource.asObservable();

  private contentHealthSource = new BehaviorSubject<any>([]);
  contentHealthObject = this.contentHealthSource.asObservable();

  private campaignSource = new BehaviorSubject<any>({});
  campaignObject = this.campaignSource.asObservable();

  private accessTokenSource = new BehaviorSubject<any>({});
  accessTokenObject = this.accessTokenSource.asObservable();

  private bloomAccessTokenSource = new BehaviorSubject<any>("");
  bloomAccessToken = this.bloomAccessTokenSource.asObservable();

  private hashtagSearchSource = new BehaviorSubject<any>({});
  hashtagSearchObject = this.hashtagSearchSource.asObservable();

  updateBloomAccess(access)
  {
    let userObj = access.user && access.user.length ? access.user[0] : "";
    if (userObj)
    {
      sessionStorage.setItem('user', JSON.stringify(userObj));
    } else {
      sessionStorage.removeItem('user');
    }
    this.bloomAccessTokenSource.next(userObj);
  }

  updateCoordinatorIGAccountData(id)
  {
    this.coordinatorIGAccountSource.next(id);
  }

  updateInfluencerProfileData(data)
  {
    this.influencerProfileDataSource.next(data);
  }

  updateCoordinatorLoggedIn(loggedIn)
  {
    this.coordinatorLoggedInSource.next(loggedIn);
  }

  updateContentHealthData(contentHealth)
  {
    this.contentHealthSource.next(contentHealth);
  }

  updateSearchedHashtag(hashtagObj)
  {
    this.hashtagSearchSource.next(hashtagObj);
  }


  updateCampaignData(campaign?)
  {
    console.log('updateCampaignData', campaign)
    if (campaign && campaign.id)
    {
      sessionStorage.setItem('activeCampaign', JSON.stringify(campaign));
    } else {
      sessionStorage.removeItem('activeCampaign');
    }
    this.campaignSource.next(campaign);
  }

  addProfileToCampaign(profile, campaignId, callback?)
  {
    let sessionCampaignProfileList = JSON.parse(sessionStorage.getItem('campaignProfileList'));
    let profileExist = false;
    if (!sessionCampaignProfileList) sessionCampaignProfileList = [];
    let activeListing = sessionCampaignProfileList.filter(list=>{
      return list.campaignId==campaignId;
    })[0];
    if (activeListing)
    {
      profileExist = this.isProfileInList(profile, campaignId);
    }
    if (!activeListing)
    {
      activeListing = {
        campaignId: campaignId,
        profiles: []
      }
      sessionCampaignProfileList.push(activeListing)
    }
    if (!profileExist)
    {
      activeListing.profiles.push(profile)
      sessionStorage.setItem('campaignProfileList', JSON.stringify(sessionCampaignProfileList));
    }
    
    if (callback) callback({"profile_added": profile})
  }

  returnCampaignProfilesList(campaignId)
  {
    let sessionCampaignProfileList = JSON.parse(sessionStorage.getItem('campaignProfileList'))
    if (!sessionCampaignProfileList)
    {
      return {};
    } else {
      let activeListing = sessionCampaignProfileList.filter(list=>{
        return list.campaignId==campaignId;
      })[0];
      return activeListing;
    }
  }

  isProfileInList(profile, campaignId)
  {
    let sessionCampaignProfileList = JSON.parse(sessionStorage.getItem('campaignProfileList'));
    if (sessionCampaignProfileList)
    {
      let profileInList = false;
      let activeListing = sessionCampaignProfileList.filter(list=>{
        return list.campaignId==campaignId;
      })[0];
      if (activeListing && activeListing.profiles)
      {
        profileInList = activeListing.profiles.filter(p=>{
          return (profile.id == p.id)
        })[0]
      }
      return profileInList ? profileInList : false;
    } else {
      return false;
    }
  }

  removeProfilefromList(profile, campaignId, callback?)
  {
    let sessionCampaignProfileList = JSON.parse(sessionStorage.getItem('campaignProfileList'));
    if (sessionCampaignProfileList)
    {
      let activeListing = sessionCampaignProfileList.filter(list=>{
        return list.campaignId==campaignId;
      })[0];
      if (activeListing.profiles)
      {
       activeListing.profiles = activeListing.profiles.filter(p=>{
         return p.id != profile.id;
       })
      }
    }
    sessionStorage.setItem('campaignProfileList', JSON.stringify(sessionCampaignProfileList));
    if (callback) callback(profile)
  }

  onUpdateInfluencer(profile, campaignId, callback?)
  {
    let sessionCampaignProfileList = JSON.parse(sessionStorage.getItem('campaignProfileList'));
    let profileInList:any = 0;
    if (sessionCampaignProfileList)
    {
      let activeListing = sessionCampaignProfileList.filter(list=>{
        return list.campaignId==campaignId;
      })[0];
      if (activeListing && activeListing.profiles)
      {
        activeListing.profiles.forEach((p, idx)=>{
          if (profile.id == p.id)
          {
            p.index = idx;
            profileInList = p;
          }
        })
      }
      if (profileInList)
      {
        activeListing.profiles[profileInList.index] = profile;
        sessionStorage.setItem('campaignProfileList', JSON.stringify(sessionCampaignProfileList));
      }
    }
    if (callback) callback(profileInList);
  }
  
}
