import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
declare var FB: any;


@Component({
  selector: 'app-hashtag-profile',
  templateUrl: './hashtag-profile.component.html',
  styleUrls: ['./hashtag-profile.component.css']
})
export class HashtagProfileComponent implements OnInit {
  hashtagProfileSub;
  coordinatorIGAccountIdSub;
  coordinatorIGAccountId;
  searchedHashtag;
  sponsoredRegex = /(partner|(#ad\b)|#sponsored|(\bad\b)|(\bsponsored\b))/i;
  filteredPosts;
  showMorePostsInView;
  sortPostsBy = 'default';

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.hashtagProfileSub = this.dataService.hashtagSearchObject.subscribe((resp) =>{
      if(resp && resp.name)
      {
        console.log(resp);
        this.searchedHashtag = resp;
        //this.postsInit();
        // this.mostRecentPostDate = new Date(this.searchedUser.media.data[0].timestamp);
        // if(this.searchedHashtag.paging && this.searchedHashtag.paging.cursors.after)
        // {
        //   this.showMorePostsInView = true;
        // }
        // else
        // {
        //   this.showMorePostsInView = false;
        // }
      }
    })

    this.coordinatorIGAccountIdSub = this.dataService.coordinatorIGAccountObject.subscribe((resp) =>{
      if(typeof resp != 'object')
      {
        this.coordinatorIGAccountId = resp;
      }
    })
  }
}
