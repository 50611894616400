import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-campaign-list',
  templateUrl: './campaign-list.component.html',
  styleUrls: ['./campaign-list.component.css']
})
export class CampaignListComponent implements OnInit {
  campaigns:any;

  constructor(public http:HttpClient, public dataService:DataService) { }

  ngOnInit() {
    this.getCampaigns();
    this.dataService.updateCampaignData();
  }

  getCampaigns()
  {
    this.http.get("https://s221289.gridserver.com/api/bloom-analytics/campaigns").subscribe(resp=>{
      let response:any = resp;
      if (response.count)
      {
        this.campaigns = response.campaigns
      }
    })
  }

}
