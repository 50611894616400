import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from "angularx-social-login";
import { FacebookLoginProvider} from "angularx-social-login";
import { DataService } from '../data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  loggedInSub;
  loggedIn;
  hasAccessToken;

  constructor(private authService: AuthService, private dataService: DataService, public router:Router)
  {
    this.loggedInSub = this.dataService.coordinatorLoggedInObject.subscribe((resp) => {
      if(typeof resp != 'object')
      {
        //console.log('RESP', resp)
        this.loggedIn = resp;
      }
    })

    this.dataService.bloomAccessToken.subscribe(token=>{
      //console.log('token', token)
      this.hasAccessToken = token;
    })
  }

  ngOnInit() {
    this.loggedIn = sessionStorage.getItem('user');
    this.hasAccessToken = sessionStorage.getItem('user');
  }

  ngOnDestroy()
  {
    this.loggedInSub.unsubscribe();
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  } 
 
  signOut(): void {
    if (this.loggedIn) this.authService.signOut();
    
    setTimeout(()=>{
      sessionStorage.clear();
      sessionStorage.setItem('facebook_account_id', '')
      sessionStorage.removeItem('facebook_account_id')
      this.router.navigate(['/']);
      this.hasAccessToken = false;
      this.dataService.updateBloomAccess("");
    }, 1000)
    
  }

}
