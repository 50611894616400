import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'bloom-analytics-test';

  constructor(public route:ActivatedRoute)
  {
  	this.route.params.subscribe(params=>{
  		console.log('params', params)
  	})
  }

  ngOnInit() {
    
  }
}
