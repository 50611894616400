import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InfluencerSearchComponent } from '../influencer-search/influencer-search.component';


@NgModule({
    declarations: [
        InfluencerSearchComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule
    ],
    exports: [
        InfluencerSearchComponent
    ],
    providers: [], //service goes here
    bootstrap: [InfluencerSearchComponent]
})
  
export class InfluencerSearchModule { }