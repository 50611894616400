import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-campaign-card',
  templateUrl: './campaign-card.component.html',
  styleUrls: ['./campaign-card.component.css']
})
export class CampaignCardComponent implements OnInit {
  @Input() campaign;

  constructor(private dataService: DataService, public router:Router) { }

  ngOnInit() {
  }

  onCampaignCardClick()
  {
    this.dataService.updateCampaignData(this.campaign);
    this.router.navigate(['/campaign', this.campaign.id]);
  }

}
