import { Component, OnDestroy, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { DataService } from '../data.service';
import { FacebookApiService } from '../facebook-api.service';
import { Router } from '@angular/router';
declare var FB: any;

@Component({
  selector: 'app-influencer-search',
  templateUrl: './influencer-search.component.html',
  styleUrls: ['./influencer-search.component.css']
})
export class InfluencerSearchComponent implements OnInit {
  @Input() view;
  @ViewChild('profileSearch') profileSearch:ElementRef;
  coordinatorIGAccountIdSub: any;
  coordinatorIGAccountId: string;
  hasError:boolean = false;
  searchedUser;
  fullPaginatedResponse;
  error;

  constructor(private dataService: DataService, public router:Router, private facebookApi:FacebookApiService) {
    this.coordinatorIGAccountIdSub = this.dataService.coordinatorIGAccountObject.subscribe((resp) =>{
      if(typeof resp != 'object')
      {
        this.coordinatorIGAccountId = resp;
        sessionStorage.setItem('coordinatorIGAccountId', resp)
      }
    })
  }

  ngOnInit()
  {
    if (!this.coordinatorIGAccountId) this.coordinatorIGAccountId = sessionStorage.getItem('coordinatorIGAccountId')
  }

  ngOnDestroy()
  {
    this.coordinatorIGAccountIdSub.unsubscribe();
  }

  //query graph api for entered instagram profile, note: ig profile must be a business or creator account, anything else will return error
  searchUser(user)
  {
    this.hasError = false;
    let id = this.coordinatorIGAccountId;
    let username = user.charAt(0) == "@" ? user.substring(1).toLowerCase() : user.toLowerCase();

    FB.api(
      id,
      'GET',
      {"fields":`business_discovery.username(${username}){id,name,username,biography,website,profile_picture_url,followers_count,follows_count,media_count, media.limit(50){comments_count,like_count,media_url,caption,timestamp,media_type,permalink}}`},
      (response) => {
        if(response && response.business_discovery)
        {
          this.searchedUser = response.business_discovery;
          console.log(this.searchedUser);
        }
        else
        {
          this.hasError = true;
        }
      }, err_resp=>{
        if (err_resp && err_resp.business_discovery) this.searchedUser = err_resp.business_discovery;
        if (err_resp && err_resp.error) this.hasError = true;
      }
    );
  }

  //route to influencer profile and clear search box on influencer profile page if used
  onSearchedUserClicked()
  {
    this.dataService.updateInfluencerProfileData(this.searchedUser);
    this.router.navigate(['/profile', this.searchedUser.id]);
    this.searchedUser = {};
    if(this.profileSearch)
    {
      this.profileSearch.nativeElement.value = '';
    }
  }

}
