import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HashtagSearchComponent } from './hashtag-search.component';


@NgModule({
    declarations: [
        HashtagSearchComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule
    ],
    exports: [
        HashtagSearchComponent
    ],
    providers: [], //service goes here
    bootstrap: [HashtagSearchComponent]
})
  
export class HashtagSearchModule { }