import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
declare var FB: any;

@Injectable({
  providedIn: 'root'
})
export class FacebookApiService {
  private searchUserSource = new BehaviorSubject<any>({});
  searchUserObject = this.searchUserSource.asObservable();

  constructor() { }

  searchUser(id, user)
  {
    let resp;
    FB.api(
      id,
      'GET',
      {"fields":`business_discovery.username(${user.toLowerCase()}){id,name,username,biography,website,profile_picture_url,followers_count,follows_count,media_count, media{comments_count,like_count,media_url,caption,timestamp,media_type,permalink}}`},
      (response) => {
        //add logic here to check if response
        if(response && response.business_discovery)
        {
          resp = response.business_discovery;
        }

        this.updateSearchedUser(resp);
      }
    );
  }

  updateSearchedUser(searchedUser)
  {
    this.searchUserSource.next(searchedUser);
  }
}
