import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coordinator-dashboard',
  templateUrl: './coordinator-dashboard.component.html',
  styleUrls: ['./coordinator-dashboard.component.css']
})
export class CoordinatorDashboardComponent implements OnInit {
  showSearch = "influencer";

  constructor() { }

  ngOnInit() {
  	sessionStorage.removeItem('activeCampaign');
  }

}
