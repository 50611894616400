import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CampaignReportService {

  	constructor() { }

  	returnCampaignInfluencers(campaignId)
  	{
  		let allLists = JSON.parse(sessionStorage.getItem('campaignProfileList'));
  		let campaignProfiles = allLists.filter(list=>{
  			return list.campaignId == campaignId;
  		})[0].profiles;

      campaignProfiles.forEach(i=>{
        if (i.media && i.media.data)
        {
          i.media.data = i.media.data.filter(d=>{
            let temp = d.media_url ? d.media_url.split("?")[d.media_url.split("?").length-2] : "";
            if (temp)
            {
              let urlExtension = temp.split(".")[temp.split(".").length-1].toLowerCase();
              if ( ['jpg', 'jpeg', 'png'].indexOf(urlExtension) > -1 ){
                return d;
              }
            }
          })
        }
      });
      return campaignProfiles;
  	}

  	returnActiveCampaign()
  	{
  		return JSON.parse(sessionStorage.getItem('activeCampaign'))
  	}
}
