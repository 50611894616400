import { Component, OnInit } from '@angular/core';
import { CampaignReportService } from './campaign-report.service';

@Component({
  selector: 'app-campaign-report',
  templateUrl: './campaign-report.component.html',
  styleUrls: ['./campaign-report.component.css'],
  providers: [CampaignReportService]
})
export class CampaignReportComponent implements OnInit {
  public onFileSaved: Function;
	campaign:any;
  activeLayout:string = 'layout-a';
	influencers:any;
  processing:any;

  	constructor(public campaignReportService:CampaignReportService)
  	{
  	}

  	ngOnInit() {
  		this.defineReportData();
      this.onFileSaved = this.myCallback.bind(this);
  	}

  	defineReportData()
  	{
  		this.campaign = this.campaignReportService.returnActiveCampaign();
  		this.influencers = this.campaignReportService.returnCampaignInfluencers(this.campaign.id)
  	}

    myCallback(val)
    {
        // Do something once Export returns
        this.processing = false;
    }

    changeLayout(which)
    {
      this.activeLayout = which;
    }

}
