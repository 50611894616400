import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-influencer-layout-c',
  templateUrl: './page-influencer-layout-c.component.html',
  styleUrls: ['./page-influencer-layout-c.component.css']
})
export class PageInfluencerLayoutCComponent implements OnInit {
	@Input() influencer;
	activeMediaIndex:number;
	activeMediaUrl:string;
	activeMediaUrl2:string;
  	constructor() { }

	ngOnInit() {
      	this.influencer.activeMediaIndex = this.influencer.activeMediaIndex ? this.influencer.activeMediaIndex : -1;
  		this.influencer.activeMediaIndex > -1 ? this.changeActiveMediaUrl(0, 'activeMediaIndex') : this.changeActiveMediaUrl(+1, 'activeMediaIndex');
  		this.influencer.activeMediaIndex2 = this.influencer.activeMediaIndex2 ? this.influencer.activeMediaIndex2 : -1;
  		this.influencer.activeMediaIndex2 > -1 ? this.changeActiveMediaUrl(0, 'activeMediaIndex2') : this.changeActiveMediaUrl(+1, 'activeMediaIndex2');
      
  	}

  	changeActiveMediaUrl(direction, property)
  	{
  		if (direction) this.influencer[property] = this.influencer[property] + direction;
  		if (property=='activeMediaIndex') this.activeMediaUrl = this.influencer.media.data[this.influencer[property]].media_url;
  		if (property=='activeMediaIndex2') this.activeMediaUrl2 = this.influencer.media.data[this.influencer[property]].media_url;
  	}

}
