import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
declare var FB: any;

@Component({
  selector: 'app-campaign-influencer',
  templateUrl: './campaign-influencer.component.html',
  styleUrls: ['./campaign-influencer.component.css']
})
export class CampaignInfluencerComponent implements OnInit {
  @Input() influencer;
  @Output() onRemovedFromList:EventEmitter<any> = new EventEmitter();
  showDetails:boolean = false;
  detailView = "notes";
  campaign:any;
  coordinatorIGAccountIdSub: any;
  coordinatorIGAccountId: string;

  constructor(public router:Router, private dataService:DataService) {
    this.coordinatorIGAccountIdSub = this.dataService.coordinatorIGAccountObject.subscribe((resp) =>{
      if(typeof resp != 'object')
      {
        this.coordinatorIGAccountId = resp;
      }
    })
    this.onRemoveProfilefromList = this.onRemoveProfilefromList.bind(this)
  }

  ngOnInit() {
    this.coordinatorIGAccountId = sessionStorage.getItem('coordinatorIGAccountId');
    this.campaign = this.campaign ? this.campaign : JSON.parse(sessionStorage.getItem('activeCampaign')) ? JSON.parse(sessionStorage.getItem('activeCampaign'))  : "";
  }

  searchedUser
  viewInfluencerProfile()
  {
    this.generateProfile();
  }

  onRemoveProfilefromList()
  {
    this.dataService.removeProfilefromList(this.influencer, this.campaign.id, (resp)=>{
      this.onRemovedFromList.emit(resp)
    })
  }

  generateProfile()
  {
    let instagramId = this.coordinatorIGAccountId;
    let username = this.influencer.username.toLowerCase();
    FB.api(
      instagramId,
      'GET',
      {"fields":`business_discovery.username(${username}){id,name,username,biography,website,profile_picture_url,followers_count,follows_count,media_count, media{comments_count,like_count,media_url,caption,timestamp,media_type,permalink}}`},
      (response) => {
        if(response && response.business_discovery)
        {
          this.searchedUser = response.business_discovery;
          this.dataService.updateInfluencerProfileData(this.searchedUser);
          this.router.navigate(['/profile', this.searchedUser.id]);
          this.searchedUser = {};
        }
      }
    );
  }

  onUpdateInfluencer()
  {
    // updating data in sessionStorage
    this.dataService.onUpdateInfluencer(this.influencer, this.campaign.id, (resp)=>{
      console.log('im done updating influencer profile in sessionStorage', resp)
      this.showDetails = false;
    })
  }

}
