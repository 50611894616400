import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare var FB: any;
import * as moment from 'moment';

@Component({
  selector: 'app-influencer-profile',
  templateUrl: './influencer-profile.component.html',
  styleUrls: ['./influencer-profile.component.css']
})
export class InfluencerProfileComponent implements OnInit {
  influencerProfileSub: any;
  searchedUser;
  sortPostsBy: string = 'recent';
  coordinatorIGAccountIdSub: any;
  coordinatorIGAccountId: string;
  showMorePostsInView:boolean;
  instagramSponRegex = /(partner|(#ad\b)|(#ad21\+)|#sponsored|(\bad\b)|(\bsponsored\b))/i; // /(((?<!not )sponsored)|partner|(#ad\b)|(\bad\b))/i
  hashtagRegex = /#[^\s#\.\;\,\:\!]{1,}/gmi;
  giveawayRegex = /(giveaway|(#giveaway\b)|entries|(\bgiveaway\b)|(\bG I V E A W A Y\b)|(#sweepstakes\b)|(#contest\b))/gi;
  mostRecentPostDate;
  filteredPosts;
  contentHealth;
  additionalPosts;
  campaign:any;
  campaigns:any;
  profileIsInList:any;
  campaignIdSelect:number;

  constructor(private dataService: DataService, public router:Router, public http:HttpClient) {
    

    this.dataService.campaignObject.subscribe(campaign=>{
      this.campaign = campaign.id ? campaign : "";
      if (campaign && campaign.id)
      {
        this.campaign = campaign;
        this.campaignIdSelect = campaign.id;
      }
    })

    this.addUserProfileToList = this.addUserProfileToList.bind(this)
  }

  ngOnInit()
  {
    this.getCampaigns();
    this.campaign = this.campaign ? this.campaign : JSON.parse(sessionStorage.getItem('activeCampaign')) ? JSON.parse(sessionStorage.getItem('activeCampaign'))  : "";
    this.influencerProfileSub = this.dataService.influencerProfileDataObject.subscribe((resp) =>{
      if(resp && resp.username)
      {
        this.searchedUser = resp;
        this.calculatePostMetrics();
        this.filteredPosts = this.searchedUser.media.data;
        this.mostRecentPostDate = new Date(this.searchedUser.media.data[0].timestamp);
        if(this.searchedUser.media.paging.cursors.after)
        {
          this.showMorePostsInView = true;
        }
        this.profileIsInList = this.campaign ? this.dataService.isProfileInList(resp, this.campaign.id) : "";
        console.log(this.searchedUser);
      }
    })

    this.coordinatorIGAccountIdSub = this.dataService.coordinatorIGAccountObject.subscribe((resp) =>{
      if(typeof resp != 'object')
      {
        this.coordinatorIGAccountId = resp;
      }
    })
  }

  ngOnDestroy() {
    this.influencerProfileSub.unsubscribe();
  }

  getCampaigns()
  {
    this.http.get("https://s221289.gridserver.com/api/bloom-analytics/campaigns").subscribe(resp=>{
      let response:any = resp;
      if (response.count)
      {
        this.campaigns = response.campaigns
      }
    })
  }

  justSaved:boolean;
  addUserProfileToList()
  {
    this.dataService.addProfileToCampaign(this.searchedUser, this.campaignIdSelect, (resp)=>{
      //if (this.campaign.id) this.router.navigate(['campaign', this.campaign.id])
      this.justSaved = true;
      setTimeout(()=>{
        this.justSaved = false;
      }, 3000)
    });
  }


  calculatePostMetrics()
  {
    let followerCount = this.searchedUser.followers_count;
    let totalHashtags = 0;

    this.searchedUser.media.data.forEach((d) => {
      d.engagement_rate = d.engagement_rate = !d.like_count || (d.like_count && d.like_count == 0) ?  (d.comments_count / followerCount) : (d.comments_count + d.like_count) / followerCount;
      d.thumbnail_url = d.media_url;
      d.engagements = d.like_count ? d.comments_count + d.like_count : d.comments_count;
      d.sponsored = this.instagramSponRegex.test(d.caption) ? true : false;
      d.hashtags = d.caption && d.caption.match(this.hashtagRegex) ? d.caption.match(this.hashtagRegex) : [];
      d.giveaway = this.giveawayRegex.test(d.caption) ? true : false;
      totalHashtags = totalHashtags + d.hashtags.length;
    });

    //IGTV POSTS BEFORE FILTERING THEM OUT
    // let igtvPosts = this.searchedUser.media.data.filter(post => post.media_product_type == "IGTV");
    // let igtvPostsCalculations = this.calculateInstagramMetricsHelper(igtvPosts, this.searchedUser);
    // this.searchedUser.igtv_num = igtvPosts.length;
    // this.searchedUser.igtv_avg_likes = igtvPostsCalculations.avg_likes;
    // this.searchedUser.igtv_avg_comments = igtvPostsCalculations.avg_comments;
    // this.searchedUser.igtv_avg_eng_rate = igtvPostsCalculations.avg_eng_rate;

    //POSTS WITH HIDDEN LIKES BEFORE FILTERING THEM OUT
    let hiddenPosts = this.searchedUser.media.data.filter(post => !post.like_count || (post.like_count && post.like_count == 0));
    if(hiddenPosts.length > 0) hiddenPosts.forEach(p => p.hiddenPost = true);
    this.searchedUser.num_hidden_posts = hiddenPosts.length;

    //FILTER OUT OUTLIERS
    let outlierFreeData = this.filterOutliers(this.searchedUser.media.data, "instagram");
    this.searchedUser.media.data = outlierFreeData;
    let filteredDataCalculations = this.calculateInstagramMetricsHelper(this.searchedUser.media.data, this.searchedUser);

    //BASIC METRICS INIT
    this.searchedUser.average_likes = filteredDataCalculations.avg_likes;
    this.searchedUser.average_comments = filteredDataCalculations.avg_comments;
    this.searchedUser.account_avg_eng_rate = filteredDataCalculations.avg_eng_rate;
    this.searchedUser.likes_per_250_followers = (this.searchedUser.average_likes / followerCount) * 250;
    this.searchedUser.comments_per_250_followers = (this.searchedUser.average_comments / followerCount) * 250;
    this.searchedUser.comments_to_likes_ratio = this.searchedUser.average_likes > 0 ? (this.searchedUser.average_comments / this.searchedUser.average_likes) : 0;
    this.searchedUser.likes_to_followers_ratio = this.searchedUser.average_likes / followerCount;
    this.searchedUser.avg_hashtags_per_post = this.searchedUser.media.data.length > 0 ? totalHashtags / this.searchedUser.media.data.length : 0;

    //AVG LIKES/COMMENTS FOR LAST 15 POSTS, anything under 15 doesnt matter because avg likes per post would equal avg likes per x number of posts under 15
    if(this.searchedUser.media.data.length > 15)
    {
      let last15Posts = this.sortByDate(this.searchedUser.media.data.slice(0,15));
      let last15PostsCalculations = this.calculateInstagramMetricsHelper(last15Posts, this.searchedUser);
      this.searchedUser.comment_avg_per_15 = last15PostsCalculations.avg_comments;
      this.searchedUser.likes_avg_per_15 = last15PostsCalculations.avg_likes; 
      this.searchedUser.eng_rate_per_15 = last15PostsCalculations.avg_eng_rate;
    }

    //SPONSORED POSTS ENG RATE
    let sponsoredPosts = this.searchedUser.media.data.filter(post => post.sponsored);
    let sponsoredPostsCalculations = this.calculateInstagramMetricsHelper(sponsoredPosts, this.searchedUser);
    this.searchedUser.sponsored_avg_likes = sponsoredPostsCalculations.avg_likes;
    this.searchedUser.sponsored_avg_comments = sponsoredPostsCalculations.avg_comments;
    this.searchedUser.sponsored_avg_eng_rate = sponsoredPostsCalculations.avg_eng_rate;
    this.searchedUser.sponsored_num = sponsoredPosts.length;
    this.searchedUser.saturation_rate = this.searchedUser.media.data.length > 0 ? sponsoredPosts.length / this.searchedUser.media.data.length : 0;

    //ORGANIC POSTS ENG RATE
    let organicPosts = this.searchedUser.media.data.filter(post => !post.sponsored);
    let organicPostsCalculations = this.calculateInstagramMetricsHelper(organicPosts, this.searchedUser);
    this.searchedUser.organic_avg_likes = organicPostsCalculations.avg_likes;
    this.searchedUser.organic_avg_comments = organicPostsCalculations.avg_comments;
    this.searchedUser.organic_avg_eng_rate = organicPostsCalculations.avg_eng_rate;

    this.calculatePostSpread(this.searchedUser.media.data);
    this.calculatePostFrequencyByWeek(this.searchedUser);
    this.calculateContentHealth(this.searchedUser);
  }

  calculateInstagramMetricsHelper(posts, searchedUser)
  {
    let followerCount = searchedUser.followers_count;
    let totalLikes = 0;
    let totalComments = 0;
    let avgLikes;
    let avgComments;
    let avgEngRate; 

    if(posts.length > 0)
    {
      posts.forEach((post) => {
        totalLikes = post.like_count ? totalLikes + post.like_count : 0;
        totalComments = totalComments + post.comments_count;
      })

      avgLikes = totalLikes / posts.length;
      avgComments = totalComments / posts.length;
      avgEngRate = (avgLikes + avgComments) / followerCount;
    }
    else
    {
      avgLikes = 0;
      avgComments = 0;
      avgEngRate = 0;
    }

    let calculations =  {
      'total_engagements': totalLikes + totalComments,
      'avg_engagements': avgLikes + avgComments,
      'avg_likes': avgLikes,
      'avg_comments': avgComments,
      'avg_eng_rate': avgEngRate
    }

    return calculations;
  }

  onSortPosts(string)
  {
    this.sortPostsBy = string;
    this.filteredPosts = this.searchedUser.media.data;
    
    if(string == 'recent')
    {
      this.filteredPosts = this.sortByDate(this.searchedUser.media.data);
    }
    if(string == 'top')
    {
      this.filteredPosts = this.searchedUser.media.data.sort((a,b) => {
        return b.engagements - a.engagements;
      })
    }
    if(string == 'recent-sponsored')
    {
      let filtered = this.searchedUser.media.data.filter(post => post.sponsored);
      this.filteredPosts = this.sortByDate(filtered);
    }
  }

  sortByDate(posts)
  {
    let sorted = posts.sort((a,b) => {
      let p1 = new Date(b.timestamp);
      let p2 = new Date(a.timestamp);

      if (p1 > p2) return 1;
      else if (p1 < p2) return -1;
      else return 0;
    })

    return sorted;
  }

  calculatePostSpread(data)
  {
    let postSpreadTotal = 0;
    let dates = data.map(post => new Date(post.timestamp));
    let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

    for(let i = 0; i < dates.length; i++)
    {
      if(i != dates.length - 1)
      {
        let firstDate = dates[i];
        let secondDate = dates[i+1];
        let diffDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
        postSpreadTotal = postSpreadTotal + diffDays;
      }
    }
    this.searchedUser.post_spread = postSpreadTotal / data.length;
  }

  
  calculateContentHealth(searchedUser)
  {
    this.contentHealth = [];
    let data = searchedUser.media.data;
    let size = 5; 

    for (var i=0; i<data.length; i+=size) {
      let temp = {'data': data.slice(i,i+size)}
      this.contentHealth.push(temp);
    }

    this.contentHealth.forEach((s) => { //s represents our object
      let sectionCalculation = this.calculateInstagramMetricsHelper(s.data, searchedUser);
      s.avg_likes = sectionCalculation.avg_likes;
      s.avg_comments = sectionCalculation.avg_comments;
      s.avg_eng_rate = sectionCalculation.avg_eng_rate;
    })

    let finalContentHealth = this.contentHealth.reverse();
    this.dataService.updateContentHealthData(finalContentHealth);
  }

  //used to get more posts from graph api if user clicks "view more posts", note: calculatePostMetrics will take into account the next 25 posts instagram returns
  getMorePosts()
  {
    let instagramId = this.coordinatorIGAccountId;
    let user = this.searchedUser.username;
    if(this.searchedUser.media.paging.cursors.after)
    {
      let after_cursor = this.searchedUser.media.paging.cursors.after;
      this.showMorePostsInView = true;
      FB.api(
        instagramId,
        'GET',
        {"fields":`business_discovery.username(${user}){media.after(${after_cursor}){comments_count,like_count,media_url,caption,timestamp,media_type,permalink}}`},
        (response) => {
          if(response && response.business_discovery)
          {
            this.additionalPosts = response.business_discovery.media;
  
            this.additionalPosts.data.forEach((ap) => {
              this.searchedUser.media.data.push(ap);
            })
            this.searchedUser.media.paging = this.additionalPosts.paging;
            this.calculatePostMetrics();
            this.filteredPosts = this.searchedUser.media.data;
          }
        }
      );
    }
    else
    {
      this.showMorePostsInView = false;
    }
    
  }

  filterOutliers(data, str)
  {
      let values, q1, q3;
      values = data.slice().sort((a,b) => a.engagements - b.engagements); //copy array and sort from least to greatest
      let filteredValues;
      let potentialOutliers;
      if(values.length >= 10)
      {
          if((values.length / 4) % 1 === 0){ //find quartiles
              q1 = 0.5 * (values[(values.length / 4)].engagements + values[(values.length / 4) + 1].engagements);
              q3 = 0.5 * (values[(values.length * (0.75))].engagements + values[(values.length * (0.75)) + 1].engagements);
          } else {
              q1 = values[Math.floor(values.length / 4 + 1)].engagements;
              q3 = values[Math.ceil(values.length * (3 / 4) + 1)].engagements;
          }

          let iqr = q3 - q1;
          let multiplier = iqr * 4.0;
          let maxValue = q3 + multiplier;
          let minValue = q1 - multiplier <= 0 ? 0 : q1 - multiplier;
          //minValue = 0;

          let socialMediaFilter = this.socialMediaSpecificFilter(minValue, maxValue, values, str);
          filteredValues = socialMediaFilter.filteredValues;
          potentialOutliers = socialMediaFilter.potentialOutliers;
          // filteredValues = values.filter((x) => ((x.engagements >= minValue) && (x.engagements <= maxValue)) && !x.giveaway && (!x.media_product_type || (x.media_product_type && x.media_product_type != 'IGTV')));
          // let potentialOutliers = values.filter((x) => ((x.engagements < minValue) || (x.engagements > maxValue)) || x.giveaway || (x.media_product_type && x.media_product_type == 'IGTV'));
          // if(this.removedTweets && this.removedTweets.length > 0)
          // {
          //     potentialOutliers = this.removedTweets.concat(potentialOutliers);
          // }
          this.sortByDate(filteredValues);
      }
      else
      {
          //socialMediaFilter = {"filteredValues": data, "potentialOutliers": []};
          filteredValues = data;
      }

      return filteredValues;
  }

  socialMediaSpecificFilter(minValue, maxValue, values, str)
  {
    let filter:any = {};

    if(str == "instagram")
    {
      filter.filteredValues = values.filter((x) => ((x.engagements >= minValue) && (x.engagements <= maxValue)) && !x.giveaway && (x.like_count && x.like_count > 0));
      //filter.filteredValues = values.filter((x) => ((x.engagements >= minValue) && (x.engagements <= maxValue)) && !x.giveaway && (!x.media_product_type || (x.media_product_type && x.media_product_type != 'IGTV')));
      filter.potentialOutliers = values.filter((x) => ((x.engagements < minValue) || (x.engagements > maxValue)) || x.giveaway || (!x.like_count || (x.like_count && x.like_count == 0)));
      //filter.potentialOutliers = values.filter((x) => ((x.engagements < minValue) || (x.engagements > maxValue)) || x.giveaway || (x.media_product_type && x.media_product_type == 'IGTV'));
    }

    return filter;
  }

  calculatePostFrequencyByWeek(searchedUser)
  {
    let posts = searchedUser.media.data ? searchedUser.media.data : searchedUser.media;
    let numPosts = posts.length;
    let numWeeks;

    if(numPosts > 0)
    {
      //using these two values for numWeeks calculation only counts number of FULLY PASSED WEEKS in between most recent and least recent post
      let mostRecentPostDate = posts[0].timestamp ? moment(posts[0].timestamp) : moment(posts[0].created_at); //get most recent post date and transform to moment
      let leastRecentPostDate = posts[numPosts - 1].timestamp ? moment(posts[numPosts - 1].timestamp) : moment(posts[numPosts - 1].created_at); //get least recent post date and transform to moment

      //using these two values for numWeeks calculation gets total number of weeks between most and least recent regardless of it they have passed or not
      //these variables provide a number that is more accurate to a human when they calculate this for themselves
      const startOfLeastRecentPostWeek = leastRecentPostDate.startOf('week');
      const startOfMostRecentPostNextWeek = mostRecentPostDate.add(1, 'weeks').startOf('week');

      numWeeks = startOfMostRecentPostNextWeek.diff(startOfLeastRecentPostWeek, 'week');
    }

    searchedUser.post_frequency_by_week = numPosts > 0 ? numPosts / numWeeks : 0;
  }

}
