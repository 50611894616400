import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* components */
import { AuthComponent } from './auth/auth.component';
import { InfluencerProfileComponent } from './influencer-profile/influencer-profile.component';
import { CoordinatorDashboardComponent } from './coordinator-dashboard/coordinator-dashboard.component';
import { CampaignComponent } from './campaign/campaign.component';
import { CampaignReportComponent } from './campaign-report/campaign-report.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { HashtagProfileComponent } from './hashtag-profile/hashtag-profile.component';

const routes: Routes = [
  {path: '', component: AuthComponent},
  {path: 'dashboard', component: CoordinatorDashboardComponent},
  {path:'profile/:id', component: InfluencerProfileComponent},
  {path:'hashtag/:id', component: HashtagProfileComponent},
  {path: 'campaign', component: CampaignComponent},
  {path: 'campaign/:id', component: CampaignComponent},
  {path: 'campaign/:id/:view', component: CampaignComponent},
  {path: 'privacy', component: PrivacyComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }