import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { AppRoutingModule } from './app-routing.module';

import { SocialLoginModule, AuthServiceConfig, LoginOpt } from "angularx-social-login";
import { FacebookLoginProvider } from "angularx-social-login";
import { NavComponent } from './nav/nav.component';
import { InfluencerProfileComponent } from './influencer-profile/influencer-profile.component';
import { CoordinatorDashboardComponent } from './coordinator-dashboard/coordinator-dashboard.component';
import { ContentHealthChartComponent } from './influencer-profile/content-health-chart/content-health-chart.component';

import { CampaignModule } from './campaign/campaign.module';
import { InfluencerSearchModule } from './influencer-search/influencer-search.module';
import { PrivacyComponent } from './privacy/privacy.component';
import { HashtagSearchModule } from './hashtag-search/hashtag-search.module';
import { HashtagProfileModule } from './hashtag-profile/hashtag-profile.module';

const fbLoginOptions: LoginOpt = {
  scope: 'public_profile,email,pages_show_list,instagram_basic,instagram_manage_insights,manage_pages',
  return_scopes: true,
  enable_profile_selector: true
};

let config = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("551517032445690", fbLoginOptions)
  }
]);
 
export function provideConfig() {
  return config;
}


@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    NavComponent,
    InfluencerProfileComponent,
    CoordinatorDashboardComponent,
    ContentHealthChartComponent,
    PrivacyComponent
  ],
  imports: [
    BrowserModule,
    SocialLoginModule,
    FormsModule,
    AppRoutingModule,
    CampaignModule,
    InfluencerSearchModule,
    HashtagProfileModule,
    HashtagSearchModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
