import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-campaign-create',
  templateUrl: './campaign-create.component.html',
  styleUrls: ['./campaign-create.component.css']
})
export class CampaignCreateComponent implements OnInit {
  months:any = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  owners:any = [
    {'name': "Stephanie Barron"},
    {'name': "Chaise Belt"},
    {'name': "Anne Lawton Blanchard"},
    {'name': "Victoria Finley"},
    {'name': "Rebecca Gillespie"},
    {'name': "Jessica Koop"},
    {'name': "Alison Matlack"},
    {'name': 'Khanh Ngo'},
    {'name': "Winnie Todo"},
    {'name': "Lauren Werner"}
  ];
  campaign:any = {
    "customer":"",
    "brand":"",
    "name":"",
    "startDate":"",
    "startDateMM":"",
    "startDateDD":"",
    "starteDateYYYY":"",
    "endDate":"",
    "endDateMM":"",
    "endDateDD":"",
    "endDateYYYY":""
  }
  constructor() { }

  ngOnInit() {
  }

  onOwnerToggle(owner, e)
  {
    console.log(owner);
    console.log(e);
  }

}
