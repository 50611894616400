import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { HashtagProfileComponent } from './hashtag-profile.component';
import { HashtagSearchModule } from '../hashtag-search/hashtag-search.module';
import { HashtagPostsComponent } from './hashtag-posts/hashtag-posts.component';

@NgModule({
    declarations: [
        HashtagProfileComponent,
        HashtagPostsComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        HashtagSearchModule
    ],
    exports: [
        HashtagProfileComponent
    ],
    providers: [], //service goes here
    bootstrap: [HashtagProfileComponent]
})
  
export class HashtagProfileModule { }