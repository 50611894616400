import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from '../../data.service';
import { BehaviorSubject } from 'rxjs';
import * as Chart from 'chart.js';

@Component({
  selector: 'app-content-health-chart',
  templateUrl: './content-health-chart.component.html',
  styleUrls: ['./content-health-chart.component.css']
})
export class ContentHealthChartComponent implements AfterViewInit {
  @ViewChild('erCanvas') erCanvas: ElementRef;
  @ViewChild('likesCanvas') likesCanvas: ElementRef;
  @ViewChild('commentsCanvas') commentsCanvas: ElementRef;
  public context: CanvasRenderingContext2D;
  erChart = new BehaviorSubject<any>([]);
  likesChart = new BehaviorSubject<any>([]);
  commentsChart = new BehaviorSubject<any>([]);

  contentHealthSub;
  contentHealth;
  responses:number = 0;

  constructor(private dataService:DataService) {
    this.contentHealthSub = this.dataService.contentHealthObject.subscribe((resp) => {
      if(resp)
      {
        this.responses++;
        this.contentHealth = resp;
      }
      if(this.responses > 1)
      {
        this.createERLineChart();
        this.createLikesLineChart();
        this.createCommentsLineChart();
      }
    })
  }

  ngAfterViewInit() {
    if(this.contentHealth)
    {
      this.createERLineChart();
      this.createLikesLineChart();
      this.createCommentsLineChart();
    }
  }

  ngOnDestroy(): void {
    this.contentHealthSub.unsubscribe();
  }

  createERLineChart()
  {
    let er = [];
    this.contentHealth.forEach((c) => {
      er.push(c.avg_eng_rate);
    })

    let labels = this.createLabels(er)

    this.context = (<HTMLCanvasElement>this.erCanvas.nativeElement).getContext('2d');
    let tempChart = new Chart(this.context, {
      type:'line',
      data: {
        labels: labels,
        datasets: [{
          data: er,
          label: "Average Engagement Rate",
          borderColor: "#833ab4",
          fill: false,
          pointBackgroundColor: "#833ab4",
          pointBorderColor: "#833ab4",
          pointRadius: 4
        }]
      },
      options: {
        title: {
          display:true,
          text: 'Average Engagement Rate By Content Grouping'
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Avg Engagement Rate'
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Content Groupings (From Least to Most Recent)'
            }
          }]
        }
      }
    })

    this.erChart.next(tempChart); //this stops change detection from freaking out
  }

  createLikesLineChart()
  {
    let likesArr = [];
    this.contentHealth.forEach((c) => {
      likesArr.push(c.avg_likes);
    })

    let labels = this.createLabels(likesArr)

    this.context = (<HTMLCanvasElement>this.likesCanvas.nativeElement).getContext('2d');
    let tempChart = new Chart(this.context, {
      type:'line',
      data: {
        labels: labels,
        datasets: [{
          data: likesArr,
          label: "Average Likes",
          borderColor: "#E86461",
          fill: false,
          pointBackgroundColor: "#E86461",
          pointBorderColor: "#E86461",
          pointRadius: 4
        }]
      },
      options: {
        title: {
          display:true,
          text: 'Average Likes By Content Grouping'
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Avg Likes'
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Content Groupings (From Least to Most Recent)'
            }
          }]
        }
      }
    })

    this.likesChart.next(tempChart); //this stops change detection from freaking out
  }

  createCommentsLineChart()
  {
    let commentsArr = [];
    this.contentHealth.forEach((c) => {
      commentsArr.push(c.avg_comments);
    })

    let labels = this.createLabels(commentsArr)

    this.context = (<HTMLCanvasElement>this.commentsCanvas.nativeElement).getContext('2d');
    let tempChart = new Chart(this.context, {
      type:'line',
      data: {
        labels: labels,
        datasets: [{
          data: commentsArr,
          label: "Average Comments",
          borderColor: "#0E68ED",
          fill: false,
          pointBackgroundColor: "#0E68ED",
          pointBorderColor: "#0E68ED",
          pointRadius: 4
        }]
      },
      options: {
        title: {
          display:true,
          text: 'Average Comments By Content Grouping'
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Avg Comments'
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Content Groupings (From Least to Most Recent)'
            }
          }]
        }
      }
    })

    this.commentsChart.next(tempChart); //this stops change detection from freaking out
  }

  createLabels(arr)
  {
    let labels = [];
    for(let i = 1; i <= arr.length; i++)
    {
      labels.push(i);
    }
    return labels;
  }

}
