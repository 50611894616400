import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService, SocialUser } from "angularx-social-login";
import { FacebookLoginProvider} from "angularx-social-login";
import { DataService } from '../data.service';
import { Router } from '@angular/router';
declare var FB: any;

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css'],
  providers: [ HttpClient]
})
export class AuthComponent implements OnInit {
  //FOR ALL FB.API CALLS, MAKE RESPONSE FUNCTION AN ARROW FUNCTION SO THAT FB WILL NOT CREATE A NEW SCOPE (NEW SCOPE MESSES UP 'THIS.')
  public igData:any = {};

  private user: SocialUser;
  cred:any = {u:"", p:""};
  loggedIn: boolean = false;
  pageSelectedId:string;
  pageSelected:boolean = false;
  rememberPageSelection:boolean;
  loginError:any;
  hasToken:any;

  constructor(private authService: AuthService, private dataService: DataService, public router:Router, public http:HttpClient)
  {
    this.dataService.bloomAccessToken.subscribe(resp=>{
      this.hasToken = resp ? true : false;
    })
  }

  ngOnInit() {
    this.checkAccessToken();
    this.checkAuthState();
  }

  onLogIn()
  {
    //console.log('onLogIn', this.cred)
    this.http.post("https://s221289.gridserver.com/api/bloom-analytics/login", JSON.stringify(this.cred)).subscribe(resp=>{
      //console.log(resp)
      let response:any = resp;
      if (response.user)
      {
        this.dataService.updateBloomAccess(response);
        this.checkAccessToken();
      } else {
        this.loginError = true;
      }
    })
  }

  checkAccessToken()
  {
    this.hasToken = sessionStorage.getItem('user');
  }

  checkAuthState()
  {
    this.rememberPageSelection = false;
    let facebook_account_id = (sessionStorage.getItem("facebook_account_id"));
    let stored_page_id = JSON.parse(sessionStorage.getItem('page_id'));

    this.authService.authState.subscribe((user) => {
      if(user)
      {
        //console.log(user);
        this.user = user;
        this.loggedIn = (user != null);
        this.dataService.updateCoordinatorLoggedIn(this.loggedIn);

        sessionStorage.setItem("facebook_account_id", this.user.id);

        //check if this user has signed in before and opted to have us remember their page
        //if so, we don't need to request their pages since we already have it saved to local storage, instead let's just get their ig id
        if((facebook_account_id == this.user.id) && stored_page_id)
        {
          this.pageSelectedId = stored_page_id;
          this.pageSelected = true;
          this.getIGBusinessAccount();
        }
        else
        {
          //console.log("request pages")
          this.requestPages(this.user.authToken);
        }
      }
      else
      {
        this.loggedIn = false;
        this.dataService.updateCoordinatorLoggedIn(false);
      }
    });
  }

  //sign in method from angularx-social-login package
  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  //request pages from graph api, needed on first login in order for us to get ig account id
  requestPages(authToken)
  {
    FB.api(
      '/me/accounts',
      'GET',
      {authToken},
      (response) => {
        //console.log(response);
        this.igData.pages = response.data;
      }
    )
  }

  onPageSelected(page)
  {
    this.pageSelectedId = '/' + page.id;
  }

  rememberPage()
  {
    if(this.rememberPageSelection)
    {
      sessionStorage.setItem('page_id', JSON.stringify(this.pageSelectedId));
    }
  }

  instagramId;
  submitPageSelection()
  {
    this.pageSelected = true;
    this.rememberPage();
    this.getIGBusinessAccount();
  }

  //once a page is selected we'll be able to get the instagram account id associated with the selected page
  getIGBusinessAccount()
  {
    //console.log('getIGBusinessAccount this.pageSelectedId = ', this.pageSelectedId)
    FB.api(
      this.pageSelectedId,
      'GET',
      {"fields":"instagram_business_account"},
      (response) => {
        if(response && ( response.id ||  response.instagram_business_account) ) 
        {
          this.updateIGAccountId(response.instagram_business_account.id ? response.instagram_business_account.id : response.id);
        } else {
          //console.log(response)
        }
      }, error=>{
        this.updateIGAccountId(error.instagram_business_account.id);
        //console.log('ERROR', error)
      }
    );
  }

  updateIGAccountId(id)
  {
    //console.log('updateIGAccountId', id)
    this.dataService.updateCoordinatorIGAccountData('/' + id);
    this.router.navigate(['/dashboard']);
  }
}
