import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { CampaignComponent } from './campaign.component';
import { CampaignCreateComponent } from './campaign-create/campaign-create.component';
import { CampaignListComponent } from './campaign-list/campaign-list.component';
import { CampaignCardComponent } from './campaign-card/campaign-card.component';
import { CampaignDetailComponent } from './campaign-detail/campaign-detail.component';
import { CampaignInfluencerComponent } from './campaign-influencer/campaign-influencer.component';
import { InfluencerSearchModule } from '../influencer-search/influencer-search.module';
import { CampaignReportModule } from '../campaign-report/campaign-report.module';

@NgModule({
    declarations: [
        CampaignComponent,
        CampaignCreateComponent,
        CampaignListComponent,
        CampaignCardComponent,
        CampaignDetailComponent,
        CampaignInfluencerComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        InfluencerSearchModule,
        PDFExportModule,
        RouterModule,
        CampaignReportModule
    ],
    exports: [
        CampaignComponent
    ],
    providers: [], //service goes here
    bootstrap: [CampaignComponent]
})
  
export class CampaignModule { }