import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { CampaignReportComponent } from './campaign-report.component';
import { DataService } from '../data.service';
import { PageInfluencerLayoutAComponent } from './templates/page-influencer-layout-a/page-influencer-layout-a.component';
import { PageInfluencerLayoutBComponent } from './templates/page-influencer-layout-b/page-influencer-layout-b.component';
import { PageInfluencerLayoutCComponent } from './templates/page-influencer-layout-c/page-influencer-layout-c.component';

@NgModule({
  declarations: [CampaignReportComponent, PageInfluencerLayoutAComponent, PageInfluencerLayoutBComponent, PageInfluencerLayoutCComponent],
  imports: [CommonModule, PDFExportModule ],
  exports: [CampaignReportComponent],
  entryComponents: [CampaignReportComponent]
})
export class CampaignReportModule { }
